import React, { useState, useEffect } from "react";
import moment from "moment";
import Header from "../../Header/Header";
import NumberTotal from "../../Cards/NumberTotal";
import SideControls from "../../Controls/SideControls";
import Sidebar from "../../Sidebar/Sidebar";
import { cleanPhoneNumber, getData } from "../../../../utils/functions";
import HandleErrors from "../../../../utils/handleErrors";
import { Calendar, momentLocalizer } from "react-big-calendar";
import ReactPaginate from "react-paginate";

import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const TotalUsers = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [filterType, setFilterType] = useState("client");
  const [agendamentos, setAgendamentos] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    try {
      getData("bookings", setUsers, setIsLoading, setError).then((data) => {
        const eventos = users.map((agendamento) => ({
          title: `Paciente: ${agendamento?.client?.name}`,
          start: new Date(
            `${(agendamento?.data).split("T")[0]}T${agendamento.time_inicial}`
          ),
          end: new Date(
            `${agendamento.data.split("T")[0]}T${agendamento.time_final}`
          ),
        }));
        setAgendamentos(eventos);
      });
    } catch (error) {
      setError(error);
    }
  }, [users]);

  const handleFilterChange = (type) => {
    setFilterType(type);
    setSearchTerm("");
  };

  const filteredBookings = users.filter((user) => {
    const searchValue = searchTerm.toLowerCase();
    if (filterType === "client") {
      return user?.client?.name.toLowerCase().includes(searchValue);
    } else if (filterType === "user") {
      return user?.user?.name.toLowerCase().includes(searchValue);
    }
    return false;
  });

  const pageCount = Math.ceil(filteredBookings.length / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredBookings.slice(offset, offset + itemsPerPage);

  return (
    <>
      <div className="col-12">
        <div className="card recent-sales">
          <div className="filter">
            <a className="icon" href={`/bookings/new`}>
              <i
                className="bi bi-plus-square-fill"
                style={{ fontSize: "24px" }}
              ></i>
            </a>
          </div>
          <div className="card-body overflow-auto">
            <h5 className="card-title">
              Agendamentos <span>| Total</span>
            </h5>

            <form className="row g-3 mt-4 mb-4 w-50 align-items-center">
              <div className="col-12">
                <label className="form-label">Nome</label>
                <div className="input-group">
                  <input
                    className="form-control"
                    name="user"
                    placeholder="Buscar por nome"
                    required
                    onChange={(e) => setSearchTerm(e.target.value)}
                    id="user"
                  />
                  <a
                    className="icon btn btn-primary"
                    href="/admin"
                    data-bs-toggle="dropdown"
                  >
                    <i className="bi bi-three-dots"></i>
                  </a>

                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow filter">
                    <li className="dropdown-header text-start">
                      <h6>Ação</h6>
                    </li>

                    <li>
                      <button
                        type="button"
                        className="btn btn-primary m-2"
                        onClick={() => handleFilterChange("client")}
                      >
                        Filtrar por Paciente
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="btn btn-primary m-2"
                        onClick={() => handleFilterChange("user")}
                      >
                        Filtrar por Dentista
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </form>

            {isLoading ? (
              <div>Carregando...</div>
            ) : error ? (
              <HandleErrors err={error}></HandleErrors>
            ) : (
              <>
                <table className="table table-borderless datatable">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Paciente</th>
                      <th scope="col">Dentista</th>
                      <th scope="col">Criado por</th>
                      <th scope="col">Atualizado por</th>
                      <th scope="col">Data</th>
                      <th scope="col">Horário</th>
                      <th scope="col">Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((user, index) => (
                      <tr key={user._id}>
                        <th scope="row">{offset + index + 1}</th>

                        <td>{user?.client?.name}</td>
                        <td>{user?.user?.name}</td>
                        <td>{user?.createdBy}</td>
                        <td>{user?.updatedBy}</td>
                        <td>{moment(user?.data).format("DD/MM/YYYY")}</td>
                        <td>
                          {user?.time_inicial} - {user?.time_final}
                        </td>
                        <td>
                          <a
                            className="icon btn btn-primary"
                            href="/admin"
                            data-bs-toggle="dropdown"
                          >
                            <i className="bi bi-three-dots"></i>
                          </a>
                          <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow filter">
                            <li className="dropdown-header text-start">
                              <h6>Ação</h6>
                            </li>
                            {user?.client?.tel ? (
                              <li>
                                <a
                                  className="dropdown-item"
                                  href={`https://wa.me/55${cleanPhoneNumber(
                                    user?.client?.tel
                                  )}`}
                                >
                                  Mensagem
                                </a>
                              </li>
                            ) : (
                              <></>
                            )}

                            <li>
                              <a
                                className="dropdown-item"
                                href={`/bookings/${user._id}`}
                              >
                                Ver
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ReactPaginate
                  previousLabel={"← Anterior"}
                  nextLabel={"Próximo →"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                />
                <span className="pagination-count">
                  Página {currentPage + 1} de {pageCount}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className="col-12 mt-4"
        style={{ backgroundColor: "aliceblue", padding: 20, borderRadius: 10 }}
      >
        <Calendar
          localizer={localizer}
          events={agendamentos}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          messages={{
            next: "Próximo",
            previous: "Anterior",
            today: "Hoje",
            month: "Mês",
            week: "Semana",
            day: "Dia",
          }}
        />
      </div>
    </>
  );
};

export default function bookings() {
  return (
    <>
      <div
        className="admin"
        id="admin"
        style={{
          background: "url('../../assets/img/home.png') no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Header />

        <Sidebar />

        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Agendamentos</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/admin">Início</a>
                </li>
                <li className="breadcrumb-item active">Agendamentos</li>
              </ol>
            </nav>
          </div>

          <section className="section dashboard">
            <div className="row" style={{ margin: "10px" }}>
              <div className="row">
                <div className="col-xxl-2 col-md-6">
                  <NumberTotal route={"bookings"} />
                </div>
              </div>

              <div className="col-lg-9">
                <div className="row">
                  <TotalUsers />
                </div>
              </div>
              <SideControls />
            </div>
          </section>
        </main>
      </div>
    </>
  );
}
