import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import BASE_URL from "../../../../utils/config";

export default function Uploads({ clientId, files, onDelete }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [filename, setFilename] = useState("");

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFilenameChange = (event) => {
    setFilename(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("client", clientId);
    formData.append("filename", filename);

    try {
      const token = Cookies.get("accessToken");

      await axios.post(`${BASE_URL.key}/uploads`, formData, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      console.error("Erro ao fazer upload do arquivo:", error);
    }
  };

  const renderFileLink = (file) => {
    const del = () => {
      onDelete(file._id);
    };

    return (
      <div key={file._id} className="file-item">
        <a href={file.url} target="_blank" rel="noopener noreferrer">
          {file.filename}
        </a>
        <p>{file.description}</p>

        <button className="btn btn-danger" onClick={del}>
          Deletar arquivo
        </button>
      </div>
    );
  };

  return (
    <div>
      <section>
        <div className="row">
          <div className="card">
            <div className="card-body">
              <form
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                className="row g-3"
              >
                <div className="input-group">
                  <input
                    type="file"
                    name="file"
                    className="form-control"
                    id="file"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    name="filename"
                    className="form-control"
                    id="filename"
                    value={filename}
                    onChange={handleFilenameChange}
                    placeholder="File Name"
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Upload File
                </button>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="card">
              <div className="card-body">
                <h2>Arquivos enviados</h2>

                <div className="file-list">
                  {files.map((file) => renderFileLink(file))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
