import React from "react";
import "./side-controls.css";

import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";

const SideControls = () => {
  const { user } = useContext(AuthContext);

  return (
    <div className="col-lg-3 align-items-center d-flex flex-column">
      {user?.userData?.role === "admin" ? (
        <div className="card w-100" style={{ borderRadius: "30px" }}>
          <div className="filter">
            <a className="icon" href="/admin" data-bs-toggle="dropdown">
              <i className="bi bi-three-dots"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
              <li>
                <a className="dropdown-item" href="/controls">
                  Todos os controles
                </a>
              </li>
            </ul>
          </div>

          <div className="card-body">
            <h5 className="card-title">
              <a href={"/admin"}>
                Admin <span>| Controles</span>
              </a>
            </h5>

            <div className="side-controls">
              <div className="row col-md-12 side-controls-links mt-3">
                <div className="side-controls-link">
                  <a href={"/users"}>
                    <i className="bx bxl">
                      <i class="bi bi-person-vcard-fill"></i>
                    </i>
                  </a>
                  <p>Funcionários</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {user?.userData?.role === "admin" || user?.userData?.role === "financial" ? (
        <div className="card w-100" style={{ borderRadius: "30px" }}>
          <div className="filter">
            <a className="icon" href="/admin" data-bs-toggle="dropdown">
              <i className="bi bi-three-dots"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
              <li>
                <a className="dropdown-item" href="/controls">
                  Todos os controles
                </a>
              </li>
            </ul>
          </div>

          <div className="card-body">
            <h5 className="card-title">
              <a href={"/doctor"}>
                Dentista <span>| Controles</span>
              </a>
            </h5>

            <div className="side-controls">
              <div className="row col-md-12 side-controls-links mt-3">
                <div className="side-controls-link">
                  <a href={"/certificates"}>
                    <i className="bx bxl">
                      <i class="bi bi-file-text"></i>
                    </i>
                  </a>
                  <p>Atestado</p>
                </div>
                <div className="side-controls-link">
                  <a href={"/medicines"}>
                    <i className="bx bxl">
                      <i class="bi bi-file-text"></i>
                    </i>
                  </a>
                  <p>Medicamentos</p>
                </div>
                <div className="side-controls-link">
                  <a href={"/prescriptions"}>
                    <i className="bx bxl">
                      <i class="bi bi-file-text"></i>
                    </i>
                  </a>
                  <p>Receitas</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {user?.userData?.role === "admin" || user?.userData?.role === "secretary" ? (
        <div className="card w-100" style={{ borderRadius: "30px" }}>
          <div className="filter">
            <a className="icon" href="/admin" data-bs-toggle="dropdown">
              <i className="bi bi-three-dots"></i>
            </a>
            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
              <li>
                <a className="dropdown-item" href="/controls">
                  Todos os controles
                </a>
              </li>
            </ul>
          </div>

          <div className="card-body">
            <h5 className="card-title">
              <a href={"/secretary"}>
                Secretária <span>| Controles</span>
              </a>
            </h5>

            <div className="side-controls">
              <div className="row col-md-12 side-controls-links mt-3">
                <div className="side-controls-link">
                  <a href={"/clients"}>
                    <i className="bx bxl">
                      <i className="bi bi-people"></i>
                    </i>
                  </a>
                  <p>Pacientes</p>
                </div>
                <div className="side-controls-link">
                  <a href={"/bookings"}>
                    <i className="bx bxl">
                      <i class="bi bi-phone"></i>
                    </i>
                  </a>
                  <p>Agendamento</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SideControls;
