import React, { useEffect, useState, useContext } from "react";
import "../../../../styles/login.css";
import Header from "../../Header/Header";
import Sidebar from "../../Sidebar/Sidebar";
import { useParams } from "react-router-dom";
import moment from "moment";
import { AuthContext } from "../../../../context/AuthContext";
import Certificates from "./Certificates";
import Prescriptions from "./Prescriptions";
import Bookings from "./Bookings";
import { maskBr } from "js-brasil";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import {
  fetchData,
  deleteData,
  editOneData,
  getData,
} from "../../../../utils/functions";

import Uploads from "./Uploads";
import PhotoSelector from "./PhotoSelector";
import ToothWithImage from "./ToothWithImage";
import HandleErrors from "../../../../utils/handleErrors";

Modal.setAppElement("#root");

const photos = [
  "/assets/img/person/idoso-1.png",
  "/assets/img/person/idosa-1.png",
  "/assets/img/person/adulto-1.png",
  "/assets/img/person/adulto-2.png",
  "/assets/img/person/adulto-3.png",
  "/assets/img/person/adulto-4.png",
  "/assets/img/person/adulto-5.png",
  "/assets/img/person/adulto-6.png",
  "/assets/img/person/adulto-7.png",
  "/assets/img/person/adulto-8.png",
  "/assets/img/person/adulto-9.png",
  "/assets/img/person/adulta-1.png",
  "/assets/img/person/adulta-2.png",
  "/assets/img/person/adulta-3.png",
  "/assets/img/person/adulta-4.png",
  "/assets/img/person/adulta-5.png",
  "/assets/img/person/adulta-6.png",
  "/assets/img/person/adulta-7.png",
  "/assets/img/person/menino-1.png",
  "/assets/img/person/menino-2.png",
  "/assets/img/person/menino-3.png",
  "/assets/img/person/menina-1.png",
  "/assets/img/person/menina-2.png",
  "/assets/img/person/menina-3.png",
];

const toothStyles = [
  { top: "40px", left: "40px" },
  { top: "40px", left: "60px" },
  { top: "40px", left: "80px" },
  { top: "40px", left: "100px" },
  { top: "40px", left: "115px" },
  { top: "40px", left: "135px" },
  { top: "40px", left: "155px" },
  { top: "40px", left: "175px" },
  { top: "40px", left: "195px" },
  { top: "40px", left: "215px" },
  { top: "40px", left: "235px" },
  { top: "40px", left: "260px" },
  { top: "40px", left: "280px" },
  { top: "40px", left: "300px" },
  { top: "40px", left: "320px" },
  { top: "40px", left: "340px" },
  { top: "95px", left: "40px" },
  { top: "95px", left: "60px" },
  { top: "95px", left: "80px" },
  { top: "95px", left: "100px" },
  { top: "95px", left: "120px" },
  { top: "95px", left: "140px" },
  { top: "95px", left: "160px" },
  { top: "95px", left: "180px" },
  { top: "95px", left: "200px" },
  { top: "95px", left: "220px" },
  { top: "95px", left: "240px" },
  { top: "95px", left: "260px" },
  { top: "95px", left: "280px" },
  { top: "95px", left: "300px" },
  { top: "95px", left: "320px" },
  { top: "95px", left: "340px" },
];

const SingleClient = () => {
  const [clients, setClients] = useState({});
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [selectedTooth, setSelectedTooth] = useState(null);
  const [procedure, setProcedure] = useState("");
  const [description, setDescription] = useState("");
  const [isToothModalOpen, setIsToothModalOpen] = useState(false);
  const [files, setFiles] = useState([]);

  const [teeth, setTeeth] = useState([]);
  const [isMinor, setIsMinor] = useState(false);
  const [isLoadingFiles, setIsLoadingFiles] = useState(true);

  const [formData, setFormData] = useState({
    name: undefined,
    updatedBy: user?.userData?.name || "",
    tel: undefined,
    email: undefined,
    identity: undefined,
    status: undefined,
    insurance: undefined,
    insurance_id: undefined,
    photo: undefined,
    teeth: undefined,
    parents: undefined,
    birthdate: undefined,
    observation: undefined,
  });

  const notifySaveChanges = () => {
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 10000);
  };

  useEffect(() => {
    fetchData("clients", id, setClients, setIsLoading, setError);
    getData("uploads", setFiles, setIsLoadingFiles, setError);
  }, [id]);

  useEffect(() => {
    if (clients) {
      setFormData({
        name: clients.name || "",
        updatedBy: user?.userData?.name || "",
        tel: clients.tel || "",
        email: clients.email || "",
        identity: clients.identity || "",
        status: clients.status || "",
        insurance: clients.insurance || "",
        insurance_id: clients.insurance_id || "",
        photo: clients.photo || "",
        teeth: clients.teeth || "",
        parents: clients.parents || "",
        birthdate: clients.birthdate || "",
        observation: clients.observation || "",
      });
      if (clients?.teeth?.length === 0) {
        setTeeth(
          Array.from({ length: 32 }, (_, i) => ({
            number: i + 1,
            condition: "healthy",
          }))
        );
      } else {
        setTeeth(clients.teeth);
      }
    }
  }, [clients, user]);

  // Funções

  const navigate = useNavigate();

  const del = () => {
    deleteData(id, "clients", navigate);
  };

  const edit = () => {
    const updatedClient = { ...formData, updatedBy: user?.userData?.name };
    editOneData(id, "clients", updatedClient);
    notifySaveChanges();
  };

  const deleteFile = (fileId) => {
    deleteData(fileId, "uploads", () => {
      setFiles(files.filter((file) => file._id !== fileId));
    });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.target.name === "birthdate") {
      const birthDate = new Date(e.target.value);
      const today = new Date();
      var age = today.getFullYear() - birthDate.getFullYear();
      const month = today.getMonth() - birthDate.getMonth();

      if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      setIsMinor(age < 18);
    }
  };

  const handleSave = (selectedPhoto) => {
    setFormData({ ...formData, photo: selectedPhoto });
    const updatedClient = {
      ...formData,
      updatedBy: user?.userData?.name,
      photo: selectedPhoto,
    };
    editOneData(id, "clients", updatedClient);
    notifySaveChanges();
  };

  const openToothModal = (number) => {
    setSelectedTooth(number);
    setIsToothModalOpen(true);

    const selectedClientTooth = clients?.teeth.find(
      (tooth) => tooth.number === number
    );
    if (selectedClientTooth) {
      setProcedure(selectedClientTooth.procedure || "");
      setDescription(selectedClientTooth.description || "");
    }
  };

  const closeToothModal = () => {
    setSelectedTooth(null);
    setProcedure("");
    setDescription("");
    setIsToothModalOpen(false);
  };

  const handleToothClick = (number) => {
    openToothModal(number);
  };

  const saveProcedure = () => {
    if (selectedTooth !== null) {
      const updatedTeeth = teeth.map((tooth) =>
        tooth.number === selectedTooth
          ? { ...tooth, condition: "treated", procedure, description }
          : tooth
      );
      setTeeth(updatedTeeth);

      const updatedClient = { ...formData, teeth: updatedTeeth };
      editOneData(id, "clients", updatedClient);
      notifySaveChanges();
      closeToothModal();
    }
  };

  const deleteProcedure = () => {
    if (selectedTooth !== null) {
      const updatedTeeth = teeth.map((tooth) =>
        tooth.number === selectedTooth
          ? { ...tooth, condition: "healthy", procedure: "", description: "" }
          : tooth
      );
      setTeeth(updatedTeeth);

      const updatedClient = { ...formData, teeth: updatedTeeth };
      editOneData(id, "clients", updatedClient);
      notifySaveChanges();
      closeToothModal();
    }
  };

  const filteredClients = clients;

  const filteredFiles = files.filter((file) => file?.client?._id.includes(id));

  return (
    <>
      <div
        className="admin"
        id="admin"
        style={{
          background: "url('../../assets/img/home.png') no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Header />

        <Sidebar />

        <main id="main" className="main">
          {showNotification && (
            <div
              className="alert alert-info alert-dismissible fade show"
              style={{ position: "fixed", top: "80px", zIndex: 999 }}
              role="alert"
            >
              <i className="bi bi-info-circle me-1"></i>Alterações feitas!
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}
          <div className="pagetitle">
            <h1>Visualização</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/admin">Início</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/clients">Pacientes</a>
                </li>
                <li className="breadcrumb-item active">Painel</li>
              </ol>
            </nav>
          </div>
          {isLoading ? (
            <div>Carregando...</div>
          ) : error ? (
            <HandleErrors err={error}></HandleErrors>
          ) : (
            <div className="container" style={{ marginTop: 0 }}>
              <section className="section profile">
                <div className="row">
                  <div className="col-xl-4">
                    <div className="card">
                      <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                        <PhotoSelector
                          photos={photos}
                          onSave={handleSave}
                          img={filteredClients?.photo}
                        />
                        <h2>{filteredClients?.name}</h2>
                        <h3>{filteredClients?.insurance_id}</h3>
                      </div>
                    </div>
                    <div className="odontogram">
                      <img
                        src="/assets/img/odontograma.jpeg"
                        alt="Odontograma"
                        className="odontogram-image"
                      />
                      {teeth?.map((tooth, index) => (
                        <ToothWithImage
                          key={tooth.number}
                          number={tooth.number}
                          condition={tooth.condition}
                          procedure={tooth.procedure}
                          description={tooth.description}
                          onClick={handleToothClick}
                          style={toothStyles[index]}
                        />
                      ))}
                    </div>
                    <Modal
                      isOpen={isToothModalOpen}
                      onRequestClose={closeToothModal}
                      contentLabel="Select Procedure"
                    >
                      <h2>Selecionar procedimento</h2>
                      <div>
                        <label>Procedimento:</label>
                        <input
                          type="text"
                          value={procedure}
                          onChange={(e) => setProcedure(e.target.value)}
                        />
                      </div>
                      <div>
                        <label>Descrição:</label>
                        <input
                          type="text"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </div>
                      <button onClick={saveProcedure}>Salvar</button>
                      <button onClick={deleteProcedure}>Deletar</button>
                      <button onClick={closeToothModal}>Cancelar</button>
                    </Modal>

                    <div className="card" style={{marginTop: "30px"}}>
                      <div className="card-body profile-card pt-4 d-flex flex-column align-items-center w-100">
                        <h2 style={{margin:0, marginBottom: "10px"}}>Observação</h2>
                        <textarea rows={5} readOnly className="w-100">
                          {filteredClients?.observation}
                        </textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8">
                    <div className="card">
                      <div className="card-body pt-3">
                        <ul className="nav nav-tabs nav-tabs-bordered">
                          <li className="nav-item">
                            <button
                              className="nav-link active"
                              data-bs-toggle="tab"
                              data-bs-target="#profile-overview"
                            >
                              Visão Geral
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className="nav-link"
                              data-bs-toggle="tab"
                              data-bs-target="#profile-edit"
                            >
                              Editar Paciente
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className="nav-link"
                              data-bs-toggle="tab"
                              data-bs-target="#profile-files"
                            >
                              Arquivos
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className="nav-link"
                              data-bs-toggle="tab"
                              data-bs-target="#profile-certificates"
                            >
                              Atestados
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className="nav-link"
                              data-bs-toggle="tab"
                              data-bs-target="#profile-prescriptions"
                            >
                              Receitas
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className="nav-link"
                              data-bs-toggle="tab"
                              data-bs-target="#profile-history"
                            >
                              Histórico
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content pt-2">
                          <div
                            className="tab-pane fade show active profile-overview"
                            id="profile-overview"
                          >
                            <h5 className="card-title">Detalhes do paciente</h5>
                            <div className="row">
                              <div className="col-lg-3 col-md-4 label">
                                Nome
                              </div>
                              <div className="col-lg-9 col-md-8">
                                {filteredClients?.name}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-md-4 label">
                                Data de nascimento
                              </div>
                              <div className="col-lg-9 col-md-8">
                                {filteredClients?.birthdate &&
                                  moment(filteredClients?.birthdate).format(
                                    "DD/MM/YYYY"
                                  )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-md-4 label">
                                Telefone
                              </div>
                              <div className="col-lg-9 col-md-8">
                                {maskBr.celular(filteredClients?.tel)}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-md-4 label">
                                Email
                              </div>
                              <div className="col-lg-9 col-md-8">
                                {filteredClients?.email}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-md-4 label">
                                Identidade
                              </div>
                              <div className="col-lg-9 col-md-8">
                                {filteredClients?.identity}
                              </div>
                            </div>
                            {(filteredClients?.identity ||
                              filteredClients?.parents) && (
                              <div className="row">
                                <div className="col-lg-3 col-md- label">
                                  Responsável
                                </div>
                                <div className="col-lg-9 col-md-8">
                                  {filteredClients?.parents}
                                </div>
                              </div>
                            )}
                            <div className="row">
                              <div className="col-lg-3 col-md-4 label">
                                Situação
                              </div>
                              <div className="col-lg-9 col-md-8">
                                {filteredClients?.status}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-md-4 label">
                                Convênio
                              </div>
                              <div className="col-lg-9 col-md-8">
                                {filteredClients?.insurance}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-md-4 label">
                                ID do Convênio
                              </div>
                              <div className="col-lg-9 col-md-8">
                                {filteredClients?.insurance_id}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-3 col-md-4 label">
                                Última atualização
                              </div>
                              <div className="col-lg-9 col-md-8">
                                {moment(filteredClients?.updatedAt).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </div>
                            </div>
                            <div className="text-center">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={del}
                              >
                                Excluir
                              </button>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade profile-edit pt-3"
                            id="profile-edit"
                          >
                            <form>
                              <div className="row mb-3">
                                <label
                                  htmlFor="name"
                                  className="col-md-4 col-lg-3 col-form-label"
                                >
                                  Nome
                                </label>
                                <div className="col-md-8 col-lg-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <label
                                  htmlFor="birthdate"
                                  className="col-md-4 col-lg-3 col-form-label"
                                >
                                  Data de nascimento
                                </label>
                                <div className="col-md-8 col-lg-9">
                                  <input
                                    type="date"
                                    name="birthdate"
                                    className="form-control"
                                    id="birthdate"
                                    value={moment(formData.birthdate).format(
                                      "YYYY-MM-DD"
                                    )}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <label
                                  htmlFor="tel"
                                  className="col-md-4 col-lg-3 col-form-label"
                                >
                                  Telefone
                                </label>
                                <div className="col-md-8 col-lg-9">
                                  <input
                                    type="tel"
                                    className="form-control"
                                    id="tel"
                                    name="tel"
                                    value={formData.tel}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <label
                                  htmlFor="email"
                                  className="col-md-4 col-lg-3 col-form-label"
                                >
                                  Email
                                </label>
                                <div className="col-md-8 col-lg-9">
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <label
                                  htmlFor="identity"
                                  className="col-md-4 col-lg-3 col-form-label"
                                >
                                  Identidade
                                </label>
                                <div className="col-md-8 col-lg-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="identity"
                                    name="identity"
                                    value={maskBr.cpf(formData.identity)}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              {isMinor && (
                                <div className="row mb-3">
                                  <label
                                    htmlFor="parents"
                                    className="col-md-4 col-lg-3 col-form-label"
                                  >
                                    Responsável
                                  </label>
                                  <div className="col-md-8 col-lg-9">
                                    <input
                                      type="text"
                                      name="parents"
                                      className="form-control"
                                      value={formData.parents}
                                      onChange={handleChange}
                                      placeholder="Nome do responsável legal"
                                      id="parents"
                                      autoComplete="false"
                                      required
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="row mb-3">
                                <label
                                  htmlFor="status"
                                  className="col-md-4 col-lg-3 col-form-label"
                                >
                                  Situação
                                </label>
                                <div className="col-md-8 col-lg-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="status"
                                    name="status"
                                    value={formData.status}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <label
                                  htmlFor="insurance"
                                  className="col-md-4 col-lg-3 col-form-label"
                                >
                                  Seguro
                                </label>
                                <div className="col-md-8 col-lg-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="insurance"
                                    name="insurance"
                                    value={formData.insurance}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <label
                                  htmlFor="insurance_id"
                                  className="col-md-4 col-lg-3 col-form-label"
                                >
                                  ID do Seguro
                                </label>
                                <div className="col-md-8 col-lg-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="insurance_id"
                                    name="insurance_id"
                                    value={formData.insurance_id}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <label
                                  htmlFor="observation"
                                  className="col-md-4 col-lg-3 col-form-label"
                                >
                                  Observações
                                </label>
                                <div className="col-md-8 col-lg-9">
                                  <textarea
                                    className="form-control"
                                    id="observation"
                                    name="observation"
                                    value={formData.observation}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="row mb-3">
                                <label
                                  htmlFor="photo"
                                  className="col-md-4 col-lg-3 col-form-label"
                                >
                                  Foto
                                </label>
                                <div className="col-md-8 col-lg-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="photo"
                                    name="photo"
                                    value={formData.photo}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <div className="text-center">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={edit}
                                >
                                  Salvar
                                </button>
                              </div>
                            </form>
                          </div>
                          <div
                            className="tab-pane fade pt-3"
                            id="profile-files"
                          >
                            {isLoadingFiles ? (
                              <p>Carregando arquivos...</p>
                            ) : (
                              <>
                                <Uploads
                                  clientId={filteredClients._id}
                                  files={filteredFiles}
                                  onDelete={deleteFile}
                                />
                              </>
                            )}
                          </div>
                          <div
                            className="tab-pane fade pt-3"
                            id="profile-certificates"
                          >
                            <Certificates id={filteredClients._id} />
                          </div>
                          <div
                            className="tab-pane fade pt-3"
                            id="profile-prescriptions"
                          >
                            <Prescriptions id={filteredClients._id} />
                          </div>
                          <div
                            className="tab-pane fade pt-3"
                            id="profile-history"
                          >
                            <Bookings data={filteredClients} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
        </main>
      </div>
    </>
  );
};

export default SingleClient;
