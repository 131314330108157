import React, { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Header from "../../../Admin/Header/Header";
import Sidebar from "../../Sidebar/Sidebar";
import "../../../../styles/login.css";
import BASE_URL from "../../../../utils/config";
import { AuthContext } from "../../../../context/AuthContext";
import HandleErrors from "../../../../utils/handleErrors";

const Register = () => {
  const navigate = useNavigate();
  const { dispatch, user } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState();
  const [formData, setFormData] = useState({
    name: undefined,
    createdBy: user?.userData?.name || "indefinido",
    updatedBy: user?.userData?.name || "indefinido",
    tel: undefined,
    email: undefined,
    password: undefined,
    identity: undefined,
    terms: true,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrorMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post(`${BASE_URL.key}/auth/register`, formData)
        .then(setErrorMessage(""));
      dispatch({ type: "REGISTER_SUCCESS" });
      navigate("/login");
    } catch (err) {
      setErrorMessage(err);
    }
  };

  return (
    <>
      <div
        className="admin"
        id="admin"
        style={{
          background: "url('../../assets/img/home.png') no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Header />

        <Sidebar />

        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Funcionários</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/admin">Início</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/users">Funcionários</a>
                </li>
                <li className="breadcrumb-item active">Adicionar</li>
              </ol>
            </nav>
          </div>
          <div className="container" style={{ marginTop: 0 }}>
            <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                    <div className="d-flex justify-content-center py-4">
                      <a
                        href="/register"
                        className="logo d-flex align-items-center w-auto"
                      >
                        <img src="assets/img/logo.png" alt="" />
                        <span className="d-none d-lg-block">Mend's Clin</span>
                      </a>
                    </div>

                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="pt-4 pb-2">
                          <h5 className="card-title text-center pb-0 fs-4">
                            Registrar
                          </h5>
                          <p className="text-center small">
                            Registrar funcionário
                          </p>
                        </div>
                        {errorMessage && (
                          <HandleErrors err={errorMessage}></HandleErrors>
                        )}
                        <form className="row g-3" onSubmit={handleSubmit}>
                          <div className="col-8">
                            <label htmlFor="name" className="form-label">
                              Nome
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                value={formData.name}
                                onChange={handleInputChange}
                                placeholder="Seu nome"
                                id="name"
                                autoComplete="false"
                                required
                              />
                            </div>
                          </div>

                          <div className="col-4">
                            <label htmlFor="tel" className="form-label">
                              Telefone
                            </label>
                            <div className="input-group">
                              <input
                                type="tel"
                                name="tel"
                                className="form-control"
                                value={formData.tel}
                                onChange={handleInputChange}
                                placeholder="Seu telefone"
                                id="tel"
                                autoComplete="false"
                                required
                              />
                            </div>
                          </div>

                          <div className="col-8">
                            <label htmlFor="email" className="form-label">
                              Email
                            </label>
                            <div className="input-group">
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                value={formData.email}
                                onChange={handleInputChange}
                                placeholder="Seu email"
                                id="email"
                                autoComplete="false"
                                required
                              />
                            </div>
                          </div>

                          <div className="col-4">
                            <label htmlFor="password" className="form-label">
                              Senha
                            </label>
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              value={formData.password}
                              onChange={handleInputChange}
                              placeholder="Crie sua senha"
                              id="password"
                              minLength={8}
                              autoComplete="false"
                              required
                            />
                          </div>

                          <div className="col-4">
                            <label htmlFor="identity" className="form-label">
                              Identidade
                            </label>
                            <input
                              type="text"
                              name="identity"
                              className="form-control"
                              value={formData.identity}
                              onChange={handleInputChange}
                              placeholder="Identidade"
                              id="identity"
                              autoComplete="false"
                              required
                            />
                          </div>

                          <div className="col-4">
                            <label htmlFor="occupation" className="form-label">
                              Setor
                            </label>
                            <input
                              type="text"
                              name="occupation"
                              className="form-control"
                              value={formData.occupation}
                              onChange={handleInputChange}
                              placeholder="Selecione um setor"
                              id="occupation"
                              autoComplete="false"
                              required
                            />
                          </div>

                          <div className="col-12">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                name="terms"
                                type="checkbox"
                                value={formData.terms}
                                onChange={handleInputChange}
                                id="terms"
                                required
                              />
                              <label
                                className="form-check-label"
                                htmlFor="terms"
                              >
                                Eu concordo e aceito os{" "}
                                <a href="/register">termos e condições</a>
                              </label>
                            </div>
                          </div>

                          <div className="col-12">
                            <button
                              className="btn btn-primary w-100"
                              type="submit"
                            >
                              Registrar
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Register;
