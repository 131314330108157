import React, { useContext, useState, useEffect } from "react";
import "../../../../styles/login.css";
import "./certificates.css";
import Header from "../../Header/Header";
import Sidebar from "../../Sidebar/Sidebar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../../../utils/config";
import { AuthContext } from "../../../../context/AuthContext";
import HandleErrors from "../../../../utils/handleErrors";
import Cookies from "js-cookie";
import { getData } from "../../../../utils/functions";
import moment from "moment";

const AddCertificate = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [query, setQuery] = useState("");
  const [clientSelected, setClientSelected] = useState(false);
  const [formData, setFormData] = useState({
    client: undefined,
    user: "",
    createdBy: user?.userData?.name || "indefinido",
    updatedBy: user?.userData?.name || "indefinido",
    justification: undefined,
    time_inicial: undefined,
    time_final: undefined,
    cid: undefined,
    return: undefined,
    data: undefined,
    data_documento: Date.now(),
  });

  useEffect(() => {
    getData("users", setUsers, setIsLoading, setError);
    getData("clients", setClients, setIsLoading, setError);
  }, []);

  useEffect(() => {
    if (query.length > 0) {
      const filteredClients = clients.filter((client) =>
        client.name.toLowerCase().includes(query.toLowerCase())
      );
      setSuggestions(filteredClients);
    } else {
      setSuggestions([]);
    }
  }, [query, clients]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!clientSelected) {
      alert("Selecione um cliente válido!");
      return;
    }

    if (formData.user === "") {
      alert("Selecione um Doutor.");
      return;
    }
    try {
      const token = Cookies.get("accessToken");
      await axios
        .post(`${BASE_URL.key}/certificates`, formData, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then(setError(""));
      navigate("/certificates");
    } catch (err) {
      setError(err);
    }
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    setClientSelected(false);
  };

  const handleSuggestionClick = (client) => {
    setFormData({ ...formData, client: client._id });
    setQuery(client.name);
    setSuggestions([]);
    setClientSelected(true);
  };

  const filteredUsers = users;

  return (
    <>
      <div
        className="admin"
        id="admin"
        style={{
          background: "url('../../assets/img/home.png') no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Header />

        <Sidebar />

        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Atestado</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/admin">Início</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/certificates">Atestados</a>
                </li>
                <li className="breadcrumb-item active">Adicionar</li>
              </ol>
            </nav>
          </div>
          <div className="container" style={{ marginTop: 0 }}>
            <section className="section admin min-vh-100 d-flex flex-column align-items-center">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                    <div className="d-flex justify-content-center py-4">
                      <a
                        href="/admin"
                        className="logo d-flex align-items-center w-auto"
                      >
                        <img src="assets/img/logo.png" alt="" />
                        <span className="d-none d-lg-block">Mend's Clin</span>
                      </a>
                    </div>

                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="pt-4 pb-2">
                          <h5 className="card-title text-center pb-0 fs-4">
                            Atestado
                          </h5>
                          <p className="text-center small">Criar atestado</p>
                        </div>
                        {isLoading ? (
                          <div>Loading...</div>
                        ) : (
                          error && <HandleErrors err={error}></HandleErrors>
                        )}
                        <form className="row g-3" onSubmit={handleSubmit}>
                          <div class="col-12">
                            <label htmlFor="client" class="form-label">
                              Paciente
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                name="client"
                                id="client"
                                className="form-control"
                                value={query}
                                onChange={handleQueryChange}
                                placeholder="Digite o nome do paciente"
                                required
                              />
                            </div>
                            {suggestions.length > 0 && (
                              <ul className="suggestions-list">
                                {suggestions.map((client) => (
                                  <li
                                    key={client._id}
                                    onClick={() =>
                                      handleSuggestionClick(client)
                                    }
                                  >
                                    {client.name}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>

                          <div class="col-12">
                            <label htmlFor="user" class="form-label">
                              Doutor
                            </label>
                            <div class="input-group">
                              <select
                                name="user"
                                id="user"
                                className="form-control"
                                value={formData.user}
                                onChange={handleInputChange}
                              >
                                <option value="">Selecione um doutor</option>
                                {filteredUsers?.map((user, index) => (
                                  <option value={user._id}>{user.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div class="col-6">
                            <label htmlFor="justification" class="form-label">
                              Justificativa
                            </label>
                            <div class="input-group">
                              <select
                                name="justification"
                                id="justification"
                                className="form-control"
                                value={formData.justification}
                                onChange={handleInputChange}
                              >
                                <option value="">
                                  Selecione uma justificação
                                </option>

                                <option value="job">Trabalho</option>
                                <option value="school">Escola</option>
                                <option value="sport">Desposte</option>
                                <option value="law">Judiciário</option>
                                <option value="military">Militar</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-6">
                            <label htmlFor="cid" className="form-label">
                              CID
                            </label>
                            <input
                              type="text"
                              name="cid"
                              className="form-control"
                              value={formData.cid}
                              onChange={handleInputChange}
                              id="cid"
                              autoComplete="false"
                              required
                            />
                          </div>

                          <div className="col-4">
                            <label
                              htmlFor="time_inicial"
                              className="form-label"
                            >
                              Entrada
                            </label>
                            <input
                              type="time"
                              name="time_inicial"
                              className="form-control"
                              value={formData.time_inicial}
                              onChange={handleInputChange}
                              id="time_inicial"
                              autoComplete="false"
                              required
                            />
                          </div>

                          <div className="col-4">
                            <label htmlFor="time_final" className="form-label">
                              Saída
                            </label>
                            <input
                              type="time"
                              name="time_final"
                              className="form-control"
                              value={formData.time_final}
                              onChange={handleInputChange}
                              id="time_final"
                              autoComplete="false"
                              required
                            />
                          </div>

                          <div className="col-4">
                            <label htmlFor="return" className="form-label">
                              Retorno (d)
                            </label>
                            <input
                              type="number"
                              name="return"
                              className="form-control"
                              value={formData.return}
                              onChange={handleInputChange}
                              id="return"
                              autoComplete="false"
                              required
                            />
                          </div>

                          <div className="col-6">
                            <label htmlFor="data" className="form-label">
                              Data da consuta
                            </label>
                            <input
                              type="date"
                              name="data"
                              className="form-control"
                              value={formData.data}
                              onChange={handleInputChange}
                              id="data"
                              autoComplete="false"
                              required
                            />
                          </div>

                          <div className="col-6">
                            <label
                              htmlFor="data_documento"
                              className="form-label"
                            >
                              Data do documento
                            </label>
                            <input
                              type="date"
                              name="data_documento"
                              className="form-control"
                              defautValue={moment(formData.data_documento).format(
                                "YYYY-MM-DD"
                              )}
                              onChange={handleInputChange}
                              id="data_documento"
                              autoComplete="false"
                            />
                          </div>

                          <div className="col-12">
                            <button
                              className="btn btn-primary w-100"
                              type="submit"
                            >
                              Registrar
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default AddCertificate;
