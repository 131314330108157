import React, { useState, useEffect } from "react";
import moment from "moment";
import { cleanPhoneNumber, getData } from "../../../../utils/functions";
import HandleErrors from "../../../../utils/handleErrors";

const TotalUsers = ({ id }) => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getData("prescriptions", setUsers, setIsLoading, setError);
  }, []);

  const filteredUsers = users.filter((user) => {
    return user?.client?._id.includes(id);
  });

  return (
    <div className="col-12">
      <div className="card recent-sales">
        <div className="filter">
          <a className="icon" href={`/prescriptions/new`}>
            <i
              className="bi bi-plus-square-fill"
              style={{ fontSize: "24px" }}
            ></i>
          </a>
        </div>
        <div className="card-body overflow-auto">
          <h5 className="card-title">
            Prescrições <span>| Total</span>
          </h5>

          {isLoading ? (
            <div>Carregando...</div>
          ) : error ? (
            <HandleErrors err={error}></HandleErrors>
          ) : (
            <table className="table table-borderless datatable">
              <thead className="table-dark">
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Paciente</th>
                  <th scope="col">Dentista</th>
                  <th scope="col">Data</th>
                  <th scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user, index) => (
                  <tr key={user._id}>
                    <th scope="row">{index + 1}</th>

                    <td>{user?.client?.name}</td>
                    <td>{user?.user?.name}</td>
                    <td>{moment(user?.updatedAt).format("DD/MM/YYYY")}</td>
                    <td>
                      <a
                        className="icon btn btn-primary"
                        href="/admin"
                        data-bs-toggle="dropdown"
                      >
                        <i className="bi bi-three-dots"></i>
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow filter">
                        <li className="dropdown-header text-start">
                          <h6>Ação</h6>
                        </li>
                        {user?.client?.tel ? (
                          <li>
                            <a
                              className="dropdown-item"
                              href={`https://wa.me/55${cleanPhoneNumber(
                                user?.client?.tel
                              )}`}
                            >
                              Mensagem
                            </a>
                          </li>
                        ) : (
                          <></>
                        )}

                        <li>
                          <a
                            className="dropdown-item"
                            href={`/prescriptions/${user._id}`}
                          >
                            Ver
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default function Prescriptions({ id }) {
  return (
    <>
      <div className="row">
        <TotalUsers id={id} />
      </div>
    </>
  );
}
