import axios from "axios";
import Cookies from "js-cookie";
import BASE_URL from "./config";

const token = Cookies.get("accessToken");
const url = BASE_URL.key;

// functions.js

export const fetchData = async (
  route,
  id,
  setBudgets,
  setIsLoading,
  setError
) => {
  try {
    const result = await axios.get(`${url}/${route}/${id}`, {
      withCredentials: true,
      headers: {
        Authorization: `${token}`,
      },
    });

    setBudgets(result.data.data);
    setIsLoading(false);
  } catch (error) {
    setError("Failed to fetch data");
    setIsLoading(false);
  }
};

export const getData = async (route, setData, setLoading, setError) => {
  try {
    const result = await axios.get(`${url}/${route}`, {
      withCredentials: true,
      headers: {
        Authorization: `${token}`,
      },
    });

    setData(result.data.data);
    setLoading(false);
  } catch (error) {
    setError("Failed to fetch data");
    setLoading(false);
  }
};

export const deleteData = async (id, route, navigate) => {
  const confirmDelete = window.confirm("Tem certeza que deseja deletar?");
  if (confirmDelete) {
    try {
      axios.delete(`${url}/${route}/${id}`, {
        withCredentials: true,
        headers: {
          Authorization: `${token}`,
        },
      });
      navigate(`/${route}`);
    } catch (error) {}
  }
};

export const editData = async (id, route, formData) => {
  try {
    axios.put(`${url}/${route}/${id}`, formData, {
      withCredentials: true,
      headers: {
        Authorization: `${token}`,
      },
    });
  } catch (error) {}
};

export const editOneData = async (id, route, formData) => {
  try {
    await axios.patch(`${url}/${route}/${id}`, formData, {
      withCredentials: true,
      headers: {
        Authorization: `${token}`,
      },
    });
    console.log(formData);
  } catch (error) {
    console.log(formData);

    console.log("Failed to update client", error);
    alert("Erro ao alterar cliente");
  }
};

export const cleanPhoneNumber = (phoneNumber) => {
  return phoneNumber.replace(/\D/g, "").replace(/\s/g, "");
};
