import React from "react";

import Header from "../components/Home/Header/Header";
import Footer from "../components/Home/Footer/Footer";
import Faq from "../components/Home/Faq/Faq";
import TopBar from "../components/TopBar/TopBar";

const Home = () => {
  return (
    <>
      <TopBar />

      <Header />

      <section id="hero" class="d-flex align-items-center">
        <div class="container">
          <h1>Bem vindo ao Mend's Clin</h1>
          <a
            href="https://wa.me/5528992740822"
            class="btn-get-started scrollto"
          >
            Entrar em contato
          </a>
        </div>
      </section>

      <main id="main">
        <section id="why-us" class="why-us">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 d-flex align-items-stretch">
                <div class="content">
                  <h3>Por que escolher Mend's Clin?</h3>
                  <p>
                    Nós somos uma clínica odontológica comprometida em oferecer
                    atendimento personalizado e de qualidade, utilizando
                    equipamentos de última geração e uma equipe altamente
                    capacitada. Oferecemos uma ampla variedade de tratamentos
                    odontológicos, desde limpeza e clareamento dental até
                    implantes e ortodontia, sempre buscando inovações para
                    garantir a satisfação dos nossos pacientes.
                  </p>
                  <div class="text-center">
                    <a href="https://wa.me/5528992740822" class="more-btn">
                      Saiba mais <i class="bx bx-chevron-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" class="services">
          <div class="container">
            <div class="section-title">
              <h2>Serviços</h2>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                <div class="icon-box">
                  <div class="icon">
                    <img src="/assets/img/services/sorriso.jpg" alt="" />
                  </div>
                  <h4>
                    <a href="#clareamento">Clareamento</a>
                  </h4>
                  <p>Sorriso branco e radiante em poucas sessões.</p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                <div class="icon-box">
                  <div class="icon">
                    <img src="/assets/img/services/services2.png" alt="" />
                  </div>
                  <h4>
                    <a href="endodontia">Endodontia (Canal)</a>
                  </h4>
                  <p>
                    Tratamento de canais com precisão e tecnologia avançada.
                  </p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                <div class="icon-box">
                  <div class="icon">
                    <img src="/assets/img/services/services3.png" alt="" />
                  </div>
                  <h4>
                    <a href="implantodontia">Implantodontia</a>
                  </h4>
                  <p>
                    Implantes dentários seguros e estéticos para sorrisos
                    completos.
                  </p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div class="icon-box">
                  <div class="icon">
                    <img src="/assets/img/services/services1.png" alt="" />
                  </div>
                  <h4>
                    <a href="ortodontia">Ortodontia</a>
                  </h4>
                  <p>
                    Sorrisos alinhados e saudáveis com tratamentos ortodônticos
                    modernos.
                  </p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div class="icon-box">
                  <div class="icon">
                    <img src="/assets/img/services/protese.jpg" alt="" />
                  </div>
                  <h4>
                    <a href="protese">Prótese dentária</a>
                  </h4>
                  <p>
                    Próteses dentárias personalizadas para um sorriso natural.
                  </p>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div class="icon-box">
                  <div class="icon">
                    <img src="/assets/img/services/restauracao.jpg" alt="" />
                  </div>
                  <h4>
                    <a href="restauracao">Restauração</a>
                  </h4>
                  <p>
                    Restaurações dentárias com materiais modernos de alta
                    precisão.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div class="icon-box">
                  <div class="icon">
                    <img src="/assets/img/services/examinacao.jpg" alt="" />
                  </div>
                  <h4>
                    <a href="limpeza">Limpeza</a>
                  </h4>
                  <p>
                    Limpeza dental completa e confortável para uma boca
                    saudável.
                  </p>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                <div class="icon-box">
                  <div class="icon">
                    <img src="/assets/img/services/arcarea.jpg" alt="" />
                  </div>
                  <h4>
                    <a href="clinico">Clínico dental</a>
                  </h4>
                  <p>Cuidando da saúde bucal com profissionalismo.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Faq />
      </main>

      <Footer />
    </>
  );
};

export default Home;
