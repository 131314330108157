// imports.js
import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { AuthContext } from "../../../../context/AuthContext";

import {
  fetchData,
  editData,
  getData,
  deleteData,
} from "../../../../utils/functions";

// imports.jsx
import Header from "../../Header/Header";
import Sidebar from "../../Sidebar/Sidebar";
import HandleErrors from "../../../../utils/handleErrors";

const SingleBooking = () => {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [booking, setbookings] = useState([]);
  const [error, setError] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [isLoadingbookings, setIsLoadingbookings] = useState(true);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [isLoadingClients, setIsLoadingClients] = useState(true);
  useEffect(() => {
    fetchData("bookings", id, setbookings, setIsLoadingbookings, setError);
    getData("users", setUsers, setIsLoadingUsers, setError);
    getData("clients", setClients, setIsLoadingClients, setError);
  }, [id]);

  const notifySaveChanges = () => {
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 10000);
  };

  const filteredUsers = users;

  const filteredClients = clients;

  const navigate = useNavigate();

  const del = () => {
    deleteData(id, "bookings", navigate);
  };

  const edit = () => {
    booking.updatedBy = user?.userData?.name;
    editData(id, "bookings", booking);
    notifySaveChanges();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setbookings((prevbooking) => ({
      ...prevbooking,
      [name]: value,
    }));
  };

  return (
    <div
      className="admin"
      id="admin"
      style={{
        background: "url('../../assets/img/home.png') no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Header />

      <Sidebar />

      <main id="main" className="main">
        {showNotification && (
          <div
            className="alert alert-info alert-dismissible fade show"
            style={{ position: "fixed", top: "80px", zIndex: 999 }}
            role="alert"
          >
            <i className="bi bi-info-circle me-1"></i>Alterações feitas!
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        )}
        <div className="pagetitle">
          <h1>Visualização</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin">Início</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/bookings">Agendamentos</a>
              </li>
              <li className="breadcrumb-item active">Painel</li>
            </ol>
          </nav>
        </div>
        <div className="container">
          <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                  {isLoadingbookings ? (
                    <></>
                  ) : isLoadingClients ? (
                    <></>
                  ) : isLoadingUsers ? (
                    <></>
                  ) : (
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="pt-4 pb-2">
                          <h5 className="card-title text-center pb-0 fs-4">
                            Visualização
                          </h5>
                          <p className="text-center small">Agendamento</p>
                        </div>

                        {error && <HandleErrors err={error}></HandleErrors>}

                        <form className="row g-3" action="">
                          <div className="col-12">
                            <label htmlFor="client" className="form-label">
                              Paciente
                            </label>
                            <div className="input-group">
                              <select
                                name="client"
                                id="client"
                                className="form-control"
                                value={booking.client}
                                onChange={handleInputChange}
                              >
                                {booking.client && (
                                  <option value={booking.client._id}>
                                    {booking.client.name}
                                  </option>
                                )}
                                {filteredClients
                                  .filter(
                                    (client) =>
                                      client._id !== booking.client?._id
                                  )
                                  .map((client) => (
                                    <option key={client._id} value={client._id}>
                                      {client.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          <div class="col-12">
                            <label for="user" class="form-label">
                              Dentista
                            </label>
                            <div class="input-group">
                              <select
                                name="user"
                                id="user"
                                className="form-control"
                                value={booking.user}
                                onChange={handleInputChange}
                              >
                                {booking.user && (
                                  <option value={booking.user._id}>
                                    {booking.user.name}
                                  </option>
                                )}
                                {filteredUsers
                                  .filter(
                                    (user) => user._id !== booking.user?._id
                                  )
                                  .map((user) => (
                                    <option key={user._id} value={user._id}>
                                      {user.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-4">
                            <label htmlFor="createdAt" className="form-label">
                              Criado em
                            </label>
                            <div className="input-group">
                              <p
                                name="createdAt"
                                className="form-control"
                                id="createdAt"
                              >
                                {moment(booking.createdAt).format("DD/MM/YYYY")}
                              </p>
                            </div>
                          </div>

                          <div className="col-4">
                            <label htmlFor="updatedAt" className="form-label">
                              Atualizado em
                            </label>
                            <div className="input-group">
                              <p
                                name="updatedAt"
                                className="form-control"
                                id="updatedAt"
                              >
                                {moment(booking.updatedAt).format("DD/MM/YYYY")}
                              </p>
                            </div>
                          </div>

                          <div className="col-4">
                            <label htmlFor="status" className="form-label">
                              Status
                            </label>
                            <select
                              name="status"
                              id="status"
                              className="form-control"
                              value={booking.status}
                              onChange={handleInputChange}
                            >
                              <option value="confirmado">Confimado</option>
                              <option value="cancelado">Cancelado</option>
                            </select>
                          </div>

                          <div className="col-4">
                            <label
                              htmlFor="time_inicial"
                              className="form-label"
                            >
                              Entrada
                            </label>
                            <input
                              type="time"
                              name="time_inicial"
                              className="form-control"
                              value={booking.time_inicial}
                              onChange={handleInputChange}
                              id="time_inicial"
                              autoComplete="false"
                            />
                          </div>

                          <div className="col-4">
                            <label htmlFor="time_final" className="form-label">
                              Saída
                            </label>
                            <input
                              type="time"
                              name="time_final"
                              className="form-control"
                              value={booking.time_final}
                              onChange={handleInputChange}
                              id="time_final"
                              autoComplete="false"
                            />
                          </div>

                          <div className="col-4">
                            <label htmlFor="data" className="form-label">
                              Data da consuta
                            </label>
                            <input
                              type="date"
                              name="data"
                              className="form-control"
                              value={booking.data.split("T")[0]}
                              onChange={handleInputChange}
                              id="data"
                              autoComplete="false"
                            />
                          </div>

                          <div className="col-4">
                            <button
                              className="btn btn-secondary w-100"
                              onClick={edit}
                              type="button"
                            >
                              Editar
                            </button>
                          </div>
                          <div className="col-4">
                            <button
                              className="btn btn-danger w-100"
                              onClick={del}
                              type="button"
                            >
                              Deletar
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default SingleBooking;
