import React, { useEffect, useState } from "react";
import { getData } from "../../../utils/functions";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const getStatusClass = (status) => {
  switch (status) {
    case "confirmado":
      return "text-success";
    case "cancelado":
      return "text-danger";
    default:
      return "text-warning";
  }
};

export default function Notification() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    getData(
      "bookings",
      (data) => {
        const filteredData = data.filter((user) =>
          moment(user?.data).isAfter(moment())
        );
        const sortedData = filteredData.sort((a, b) =>
          moment(a?.data).diff(moment(b?.data))
        );
        setUsers(sortedData.slice(0, 4));
        setIsLoading(false);
      },
      setIsLoading,
      setError
    );
  }, []);

  return (
    <>
      {isLoading ? (
        <></>
      ) : error ? (
        <></>
      ) : (
        <li className="nav-item dropdown">
          <a
            className="nav-link nav-icon"
            href="/admin"
            data-bs-toggle="dropdown"
          >
            <i className="bi bi-bell"></i>
            <span className="badge bg-primary badge-number">
              {users.length}
            </span>
          </a>

          <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
            <li className="dropdown-header">
              Você possui {users.length} paciente(s).
              <a href="/bookings">
                <span className="badge rounded-pill bg-primary p-2 ms-2">
                  Ver todos
                </span>
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>

            {users.map((user) => (
              <React.Fragment key={user._id}>
                <li
                  className="notification-item"
                  onClick={() => {
                    navigate(`/bookings/${user._id}`);
                  }}
                >
                  <i
                    className={`bi bi-x-circle ${getStatusClass(user?.status)}`}
                  ></i>
                  <div>
                    <h4>{user?.client?.name}</h4>
                    <p>Com o Dr. {user?.user?.name}</p>
                    <p>
                      {moment(user?.data).add(1, "days").format("DD/MM")} às{" "}
                      {user?.time_inicial}-{user?.time_final}
                    </p>
                  </div>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
              </React.Fragment>
            ))}

            <li>
              <hr className="dropdown-divider" />
            </li>
          </ul>
        </li>
      )}
    </>
  );
}
