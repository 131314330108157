import React, { useState, useEffect } from "react";
import "../../styles/admin.css";
import Header from "../../components/Admin/Header/Header";
import Sidebar from "../../components/Admin/Sidebar/Sidebar";
import SideControls from "../../components/Admin/Controls/SideControls";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { getData } from "../../utils/functions";


const CustomEvent = ({ event }) => {
  return (
    <div>
      <strong>{event.title}</strong>
      <p>{event.description}</p>
    </div>
  );
};

const Admin = () => {
  const localizer = momentLocalizer(moment);

  const [agendamentos, setAgendamentos] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    try {
      getData("bookings", setUsers, setIsLoading, setError).then((data) => {
        const eventos = users.map((agendamento) => ({
          title: `Paciente: ${agendamento?.client?.name}`,
          description: `Dr. ${agendamento?.user?.name}`,
          start: new Date(
            `${(agendamento?.data).split("T")[0]}T${agendamento.time_inicial}`
          ),
          end: new Date(
            `${agendamento.data.split("T")[0]}T${agendamento.time_final}`
          ),
        }));
        setAgendamentos(eventos);
      });
    } catch (error) {
      setError(error);
    }
  }, [users]);

  console.log(agendamentos)

  return (
    <div
      className="admin"
      id="admin"
      style={{
        background: "url('../../assets/img/home.png') no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Header />

      <Sidebar />

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Painel Geral</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">Início</li>
            </ol>
          </nav>
        </div>

        <section className="section dashboard">
          <div className="row" style={{ margin: "10px" }}>
            <div className="row">
              <div className="col-xxl-2 col-md-6"></div>
            </div>

            <div className="col-9">
              {isLoading ? (
                <>Carregando...</>
              ) : error ? (
                <>Algo deu errado...</>
              ) : (
                <div
                  className="col-12 mt-4"
                  style={{
                    backgroundColor: "aliceblue",
                    padding: 20,
                    borderRadius: 10,
                  }}
                >
                  <Calendar
                    localizer={localizer}
                    events={agendamentos}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    messages={{
                      next: "Próximo",
                      previous: "Anterior",
                      today: "Hoje",
                      month: "Mês",
                      week: "Semana",
                      day: "Dia",
                    }}
                    components={{
                      event: CustomEvent,
                    }}
                  />
                </div>
              )}
            </div>

            <SideControls />
          </div>
        </section>
      </main>
    </div>
  );
};

export default Admin;
