import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import "../../../../styles/login.css";
import Header from "../../Header/Header";
import Sidebar from "../../Sidebar/Sidebar";
import { AuthContext } from "../../../../context/AuthContext";
import HandleErrors from "../../../../utils/handleErrors";

import {
  fetchData,
  deleteData,
  editOneData,
} from "../../../../utils/functions";

const SingleUser = () => {
  const [users, setUsers] = useState([]);
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const [formData, setFormData] = useState({
    name: undefined,
    updatedBy: user?.userData?.name || "",
    tel: undefined,
    email: undefined,
    identity: undefined,
    status: undefined,
    occupation: undefined,
    role: undefined,
    parents: undefined,
  });

  useEffect(() => {
    fetchData("users", id, setUsers, setIsLoading, setError);
  }, [id]);

  useEffect(() => {
    if (users) {
      setFormData({
        name: users.name || "",
        updatedBy: user?.userData?.name || "",
        tel: users.tel || "",
        email: users.email || "",
        identity: users.identity || "",
        status: users.status || "",
        occupation: users.occupation || "",
        role: users.role || "",
        parents: users.parents || "",
      });
    }
  }, [users, user]);

  // Functions

  const notifySaveChanges = () => {
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 10000);
  };

  const del = () => {
    deleteData(id, "users", navigate);
  };

  const edit = () => {
    const updatedUser = { ...formData, updatedBy: user?.userData?.name };
    editOneData(id, "users", updatedUser);
    notifySaveChanges();
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const filteredUsers = users;

  return (
    <>
      <div
        className="admin"
        id="admin"
        style={{
          background: "url('../../assets/img/home.png') no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Header />

        <Sidebar />

        <main id="main" className="main">
          {showNotification && (
            <div
              className="alert alert-info alert-dismissible fade show"
              style={{ position: "fixed", top: "80px", zIndex: 999 }}
              role="alert"
            >
              <i className="bi bi-info-circle me-1"></i>Alterações feitas!
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}
          <div className="pagetitle">
            <h1>Visualização</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/admin">Início</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/users">Funcionário</a>
                </li>
                <li className="breadcrumb-item active">Painel</li>
              </ol>
            </nav>
          </div>

          <div class="container" style={{ marginTop: 0 }}>
            <section class="section register min-vh-100 d-flex flex-column align-items-center">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-md-6 d-flex flex-column align-items-center justify-content-center">
                    {isLoading ? (
                      <></>
                    ) : (
                      <div class="card mb-3">
                        <div class="card-body">
                          <div class="pt-4 pb-2">
                            <h5 class="card-title text-center pb-0 fs-4">
                              Visualização
                            </h5>
                            <p class="text-center small">Funcionários</p>
                          </div>
                          {error && <HandleErrors err={error}></HandleErrors>}
                          <form class="row g-3" method="put">
                            <div class="col-6">
                              <label for="name" class="form-label">
                                Nome
                              </label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  name="name"
                                  defaultValue={filteredUsers?.name}
                                  onChange={handleChange}
                                  class="form-control"
                                  id="name"
                                  required
                                />
                              </div>
                            </div>

                            <div class="col-6">
                              <label for="name" class="form-label">
                                Identidade
                              </label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  name="identity"
                                  defaultValue={filteredUsers?.identity}
                                  onChange={handleChange}
                                  class="form-control"
                                  id="identity"
                                  required
                                />
                              </div>
                            </div>

                            <div class="col-6">
                              <label for="tel" class="form-label">
                                Telefone
                              </label>
                              <div class="input-group">
                                <input
                                  type="tel"
                                  name="tel"
                                  defaultValue={filteredUsers?.tel}
                                  onChange={handleChange}
                                  class="form-control"
                                  id="tel"
                                  required
                                />
                              </div>
                            </div>

                            <div class="col-6">
                              <label for="email" class="form-label">
                                Email
                              </label>
                              <div class="input-group">
                                <input
                                  type="email"
                                  name="email"
                                  defaultValue={filteredUsers?.email}
                                  onChange={handleChange}
                                  class="form-control"
                                  id="email"
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-6">
                              <label htmlFor="createdAt" className="form-label">
                                Criado em
                              </label>
                              <div className="input-group">
                                <p
                                  name="createdAt"
                                  className="form-control"
                                  id="createdAt"
                                >
                                  {moment(filteredUsers?.createdAt).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                              </div>
                            </div>

                            <div className="col-6">
                              <label htmlFor="updatedAt" className="form-label">
                                Atualizado em
                              </label>
                              <div className="input-group">
                                <p
                                  name="updatedAt"
                                  className="form-control"
                                  id="updatedAt"
                                >
                                  {moment(filteredUsers?.updatedAt).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                              </div>
                            </div>

                            <div className="col-4">
                              <label htmlFor="status" className="form-label">
                                Status
                              </label>
                              <div class="input-group">
                                <input
                                  className="form-control"
                                  name="status"
                                  defaultValue={filteredUsers?.status}
                                  onChange={handleChange}
                                  id="status"
                                />
                              </div>
                            </div>

                            <div className="col-4">
                              <label htmlFor="role" className="form-label">
                                Papel
                              </label>
                              <div class="input-group">
                                <input
                                  className="form-control"
                                  name="role"
                                  defaultValue={filteredUsers?.role}
                                  onChange={handleChange}
                                  id="role"
                                />
                              </div>
                            </div>

                            <div className="col-4">
                              <label
                                htmlFor="occupation"
                                className="form-label"
                              >
                                Função
                              </label>
                              <div class="input-group">
                                <input
                                  className="form-control"
                                  name="occupation"
                                  defaultValue={filteredUsers?.occupation}
                                  onChange={handleChange}
                                  id="occupation"
                                />
                              </div>
                            </div>

                            <div class="col-4">
                              <button
                                class="btn btn-secondary w-100"
                                onClick={edit}
                                type="button"
                              >
                                Editar
                              </button>
                            </div>
                            <div class="col-4">
                              <button
                                class="btn btn-danger w-100"
                                onClick={del}
                              >
                                Deletar
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default SingleUser;
