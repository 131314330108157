import React, { useContext, useState, useEffect } from "react";
import "../../../../styles/login.css";
import Header from "../../Header/Header";
import Sidebar from "../../Sidebar/Sidebar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../../../utils/config";
import { AuthContext } from "../../../../context/AuthContext";
import HandleErrors from "../../../../utils/handleErrors";
import Cookies from "js-cookie";
import { getData } from "../../../../utils/functions";

const AddPrescription = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [medicines, setMedicines] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [formData, setFormData] = useState({
    client: undefined,
    user: undefined,
    createdBy: user?.userData?.name || "indefinido",
    updatedBy: user?.userData?.name || "indefinido",
    medicines: [],
  });

  useEffect(() => {
    getData("users", setUsers, setIsLoading, setError);
    getData("clients", setClients, setIsLoading, setError);
    getData("medicines", setMedicines, setIsLoading, setError);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = Cookies.get("accessToken");
      await axios
        .post(`${BASE_URL.key}/prescriptions`, formData, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then(setError(""));
      navigate("/prescriptions");
    } catch (err) {
      setError(err);
    }
  };

  const [selectedMedicine, setSelectedMedicine] = useState(""); // Estado para armazenar o medicamento selecionado

  // Função para adicionar o medicamento selecionado à lista de medicamentos no estado formData
  const handleAddMedicine = () => {
    if (selectedMedicine) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        medicines: [...prevFormData.medicines, selectedMedicine],
      }));
      setSelectedMedicine(""); // Limpa a seleção após adicionar o medicamento à lista
    }
  };

  // Função para remover um medicamento da lista
  const handleRemoveMedicine = (medicineId) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      medicines: prevFormData.medicines.filter((id) => id !== medicineId),
    }));
  };

  const filteredUsers = users;

  const filteredClients = clients;

  const filteredMedicine = medicines;

  return (
    <>
      <div
        className="admin"
        id="admin"
        style={{
          background: "url('../../assets/img/home.png') no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Header />

        <Sidebar />

        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Receita</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/admin">Início</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/prescriptions">Receitas</a>
                </li>
                <li className="breadcrumb-item active">Adicionar</li>
              </ol>
            </nav>
          </div>
          <div className="container" style={{ marginTop: 0 }}>
            <section className="section admin min-vh-100 d-flex flex-column align-items-center">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                    <div className="d-flex justify-content-center py-4">
                      <a
                        href="/admin"
                        className="logo d-flex align-items-center w-auto"
                      >
                        <img src="assets/img/logo.png" alt="" />
                        <span className="d-none d-lg-block">Mend's Clin</span>
                      </a>
                    </div>

                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="pt-4 pb-2">
                          <h5 className="card-title text-center pb-0 fs-4">
                            Prescrição
                          </h5>
                          <p className="text-center small">Criar receita</p>
                        </div>
                        {isLoading ? (
                          <div>Loading...</div>
                        ) : (
                          error && <HandleErrors err={error}></HandleErrors>
                        )}
                        <form className="row g-3" onSubmit={handleSubmit}>
                          <div class="col-12">
                            <label htmlFor="client" class="form-label">
                              Paciente
                            </label>
                            <div class="input-group">
                              <select
                                name="client"
                                id="client"
                                className="form-control"
                                value={formData?.client}
                                onChange={handleInputChange}
                              >
                                <option value="">Selecione um paciente</option>
                                {filteredClients?.map((client, index) => (
                                  <option key={client._id} value={client._id}>
                                    {client.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div class="col-12">
                            <label htmlFor="user" class="form-label">
                              Doutor
                            </label>
                            <div class="input-group">
                              <select
                                name="user"
                                id="user"
                                className="form-control"
                                value={formData.user}
                                onChange={handleInputChange}
                              >
                                <option value="">Selecione um doutor</option>
                                {filteredUsers?.map((user, index) => (
                                  <option value={user._id}>{user.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-12">
                            <label htmlFor="medicine" className="form-label">
                              Medicamentos
                            </label>
                            <div className="input-group">
                              <select
                                name="medicine"
                                id="medicine"
                                className="form-control"
                                value={selectedMedicine}
                                onChange={(e) =>
                                  setSelectedMedicine(e.target.value)
                                }
                              >
                                <option value="">
                                  Selecione um medicamento
                                </option>
                                {filteredMedicine?.map((user, index) => (
                                  <option value={user._id}>{user.name}</option>
                                ))}
                              </select>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleAddMedicine}
                              >
                                Adicionar
                              </button>
                            </div>
                          </div>

                          {/* Lista de medicamentos selecionados */}
                          <div>
                            <ul>
                              {formData.medicines.map((medicineId) => {
                                
                                const selectedMedicine = filteredMedicine.find(
                                  (medicine) => medicine._id === medicineId
                                );
                                
                                if (selectedMedicine) {
                                  return (
                                    <div className="d-flex justify-content-between" key={medicineId}>
                                      <li>{selectedMedicine.name}</li>

                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() =>
                                          handleRemoveMedicine(medicineId)
                                        }
                                      >
                                        Remover
                                      </button>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                            </ul>
                          </div>

                          <div className="col-12">
                            <button
                              className="btn btn-primary w-100"
                              type="submit"
                            >
                              Registrar
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default AddPrescription;
