import React, { useState, useEffect } from "react";
import Header from "../../../Admin/Header/Header";
import NumberTotal from "../../Cards/NumberTotal";
import SideControls from "../../Controls/SideControls";
import Sidebar from "../../Sidebar/Sidebar";
import { cleanPhoneNumber, getData } from "../../../../utils/functions";
import { maskBr } from "js-brasil";
import ReactPaginate from "react-paginate";

const TotalUsers = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    getData("users", setUsers, setIsLoading, setError);
  }, []);

  const filteredUsers = users.filter((user) => {
    return user?.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const pageCount = Math.ceil(filteredUsers.length / itemsPerPage);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentItems = filteredUsers.slice(offset, offset + itemsPerPage);

  return (
    <div className="col-12">
      <div className="card recent-sales">
        <div className="filter">
          <a className="icon" href={`/users/new`}>
            <i
              className="bi bi-plus-square-fill"
              style={{ fontSize: "24px" }}
            ></i>
          </a>
        </div>
        <div className="card-body overflow-auto">
          <h5 className="card-title">
            Funcionários <span>| Total</span>
          </h5>

          <form className="row g-3 mt-4 mb-4 w-50 align-items-center">
            <div className="col-12">
              <label className="form-label">Nome</label>
              <div className="input-group">
                <input
                  className="form-control"
                  name="user"
                  placeholder="Buscar por nome"
                  required
                  onChange={(e) => setSearchTerm(e.target.value)}
                  id="user"
                />
              </div>
            </div>
          </form>

          {isLoading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>{error}</div>
          ) : (
            <>
              <table className="table table-borderless datatable">
                <thead className="table-dark">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nome</th>
                    <th scope="col">Telefone</th>
                    <th scope="col">Email</th>
                    <th scope="col">Identidade</th>
                    <th scope="col">Atualizado por</th>
                    <th scope="col">Cargo</th>
                    <th scope="col">Setor</th>
                    <th scope="col">Status</th>
                    <th scope="col">Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((user, index) => (
                    <tr key={user._id}>
                      <th scope="row">{offset + index + 1}</th>

                      <td>{user?.name}</td>
                      <td>{maskBr.celular(user?.tel)}</td>
                      <td>{user?.email}</td>
                      <td>{maskBr.cpf(user?.identity)}</td>
                      <td>{user?.updatedBy}</td>
                      <td>{user?.role}</td>
                      <td>{user?.occupation}</td>
                      <td>{user?.status}</td>
                      <td>
                        <a
                          className="icon btn btn-primary"
                          href="/admin"
                          data-bs-toggle="dropdown"
                        >
                          <i className="bi bi-three-dots"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow filter">
                          <li className="dropdown-header text-start">
                            <h6>Ação</h6>
                          </li>
                          {user?.tel ? (
                            <li>
                              <a
                                className="dropdown-item"
                                href={`https://wa.me/55${cleanPhoneNumber(
                                  user?.tel
                                )}`}
                              >
                                Mensagem
                              </a>
                            </li>
                          ) : (
                            <></>
                          )}
                          <li>
                            <a
                              className="dropdown-item"
                              href={`/users/${user._id}`}
                            >
                              Ver
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ReactPaginate
                previousLabel={"← Anterior"}
                nextLabel={"Próximo →"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
              />
              <span className="pagination-count">
                Página {currentPage + 1} de {pageCount}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default function Users() {
  return (
    <>
      <div
        className="admin"
        id="admin"
        style={{
          background: "url('../../assets/img/home.png') no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Header />

        <Sidebar />

        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Funcionários</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/admin">Início</a>
                </li>
                <li className="breadcrumb-item active">Funcionários</li>
              </ol>
            </nav>
          </div>

          <section className="section dashboard">
            <div className="row" style={{ margin: "10px" }}>
              <div className="row">
                <div className="col-xxl-2 col-md-6">
                  <NumberTotal route={"users"} />
                </div>
              </div>

              <div className="col-lg-9">
                <div className="row">
                  <TotalUsers />
                </div>
              </div>
              <SideControls />
            </div>
          </section>
        </main>
      </div>
    </>
  );
}
