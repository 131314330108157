import React, { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import "../../../../styles/login.css";
import Header from "../../Header/Header";
import Sidebar from "../../Sidebar/Sidebar";
import BASE_URL from "../../../../utils/config";
import { AuthContext } from "../../../../context/AuthContext";
import HandleErrors from "../../../../utils/handleErrors";
import purposeList from "./purposeList.json";
import typeList from "./typeList.json";

const AddMedicine = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [error, setError] = useState();

  const [formData, setFormData] = useState({
    name: undefined,
    purpose: undefined,
    type: undefined,
    prompt: undefined,
    createdBy: user?.userData?.name || "indefinido",
    updatedBy: user?.userData?.name || "indefinido",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = Cookies.get("accessToken");
      await axios
        .post(`${BASE_URL.key}/medicines`, formData, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then(setError(""));
      navigate("/medicines");
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <div
        className="admin"
        id="admin"
        style={{
          background: "url('../../assets/img/home.png') no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Header />

        <Sidebar />

        <main id="main" className="main">
          <div className="pagetitle">
            <h1>Pacientes</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/admin">Início</a>
                </li>
                <li className="breadcrumb-item active">Painel</li>
              </ol>
            </nav>
          </div>
          <div className="container" style={{ marginTop: 0 }}>
            <section className="section admin min-vh-100 d-flex flex-column align-items-center">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                    <div className="d-flex justify-content-center py-4">
                      <a
                        href="/admin"
                        className="logo d-flex align-items-center w-auto"
                      >
                        <img src="assets/img/logo.png" alt="" />
                        <span className="d-none d-lg-block">Mend's Clin</span>
                      </a>
                    </div>

                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="pt-4 pb-2">
                          <h5 className="card-title text-center pb-0 fs-4">
                            Paciente
                          </h5>
                          <p className="text-center small">
                            Cadastrar paciente
                          </p>
                        </div>
                        {error && <HandleErrors err={error}></HandleErrors>}
                        <form className="row g-3" onSubmit={handleSubmit}>
                          <div className="col-12">
                            <label htmlFor="name" className="form-label">
                              Medicamento
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                value={formData?.name}
                                onChange={handleInputChange}
                                placeholder="Medicamento"
                                id="name"
                                autoComplete="false"
                                required
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <label htmlFor="prompt" className="form-label">
                              Prompt
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                name="prompt"
                                className="form-control"
                                value={formData?.prompt}
                                onChange={handleInputChange}
                                placeholder="Escreva o prompt"
                                id="prompt"
                                autoComplete="false"
                                required
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <label htmlFor="purpose" className="form-label">
                              Propósito
                            </label>
                            <div className="input-group">
                              <select
                                name="purpose"
                                id="purpose"
                                className="form-control"
                                value={formData.purpose}
                                onChange={handleInputChange}
                              >
                                <option value="">Selecione um </option>
                                {purposeList?.map((purpose, index) => (
                                  <option value={purpose.name}>
                                    {purpose.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-12">
                            <label htmlFor="type" className="form-label">
                              Tipo
                            </label>
                            <div className="input-group">
                              <select
                                name="type"
                                id="type"
                                className="form-control"
                                value={formData.type}
                                onChange={handleInputChange}
                              >
                                <option value="">Selecione um </option>
                                {typeList?.map((type, index) => (
                                  <option value={type.name}>{type.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-12">
                            <button
                              className="btn btn-primary w-100"
                              type="submit"
                            >
                              Registrar
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default AddMedicine;
