import React, { useState, useEffect } from "react";
import { getData } from "../../../utils/functions";

const NumberTotal = ({ route }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterTerm, setFilterTerm] = useState("");

  useEffect(() => {
    getData(route, setData, setIsLoading, setError);
  }, [route]);

  const filteredRoute = (e) => {
    switch (e) {
      case "certificates":
        return "Atestados";
      case "users":
        return "Funcionários";
      case "clients":
        return "Pacientes";
      case "prescriptions":
        return "Receitas";
      case "medicines":
        return "Medicamentos";
        case "bookings":
          return "Agendamentos";
      default:
        return "Outros";
    }
  };

  const handleFilter = (event) => {
    const clickedFilter = [
      event.target.getAttribute("data-filter"),
      event.target.innerText,
    ];
    setFilterTerm(clickedFilter);
  };

  const filterDataByDate = (date) => {
    if (!filterTerm[0]) return true;

    const today = new Date();
    const DateObj = new Date(date);

    switch (filterTerm[0]) {
      case "day":
        return (
          DateObj.getDate() === today.getDate() &&
          DateObj.getMonth() === today.getMonth() &&
          DateObj.getFullYear() === today.getFullYear()
        );
      case "month":
        return (
          DateObj.getMonth() === today.getMonth() &&
          DateObj.getFullYear() === today.getFullYear()
        );
      case "year":
        return DateObj.getFullYear() === today.getFullYear();
      default:
        return true;
    }
  };

  const filteredData = data.filter((data) => filterDataByDate(data.updatedAt));

  return (
    <div
      className="card info-card sales-card"
      style={{ border: "solid 2px #d5d5ff" }}
    >
      <div className="filter">
        <a className="icon" href="/admin" data-bs-toggle="dropdown">
          <i className="bi bi-three-dots"></i>
        </a>
        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
          <li className="dropdown-header text-start">
            <h6>Filtro</h6>
          </li>

          <li>
            <a
              className="dropdown-item"
              href="#dropdown-item"
              data-filter="day"
              onClick={handleFilter}
            >
              No dia
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              href="#dropdown-item"
              data-filter="month"
              onClick={handleFilter}
            >
              Este mês
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              href="#dropdown-item"
              data-filter="year"
              onClick={handleFilter}
            >
              Este ano
            </a>
          </li>
        </ul>
      </div>

      <div className="card-body">
        <h5 className="card-title">
          {filteredRoute(route)}{" "}
          <span>| {filterTerm[1] ? filterTerm[1] : "Este ano"}</span>
        </h5>

        <div className="d-flex align-items-center">
          <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
            <i className="bi bi-people"></i>
          </div>
          <div className="ps-3">
            <h6>{isLoading ? "..." : filteredData.length}</h6>
            {error && <p className="text-secondary">Algo deu errado.</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberTotal;
