import React from "react";
import "../styles/not-found-page.css";

const NotFoundPage = () => (
  <>
    <section
      class="section error-404 min-vh-100 w-100 d-flex flex-column align-items-center justify-content-center"
    style={{ background: "url('../../assets/img/home.png') no-repeat", backgroundSize:"cover", backgroundPosition:"center"}}
    >
      <h1 style={{marginBottom:20}}>404</h1>
      <h2>A página que você procura não existe.</h2>
      <a class="btn" href="/">
        Voltar para o início
      </a>
    </section>
  </>
);

export default NotFoundPage;
