import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import Notification from "../Notification/Notification";
import select from "../../../utils/select";
import "./header.css";

const Header = () => {
  const { dispatch, user } = useContext(AuthContext);
  const navigate = useNavigate();

  const logout = () => {
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  const toggleSlide = () => {
    select("body").classList.toggle("toggle-sidebar");
  };

  return (
    <header
      id="header"
      className="header fixed-top d-flex align-items-center"
      style={{ top: "0" }}
    >
      <div className="d-flex align-items-center justify-content-between">
        <a href="/admin" className="logo d-flex align-items-center">
          <span className="d-none d-lg-block">Admin</span>
        </a>
        <i className="bi bi-list toggle-sidebar-btn" onClick={toggleSlide}></i>
      </div>

      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          <li className="nav-item d-block d-lg-none">
            <a className="nav-link nav-icon search-bar-toggle" href="/admin">
              <i className="bi bi-search"></i>
            </a>
          </li>

          <Notification />

          <li className="nav-item dropdown pe-3">
            <a
              className="nav-link nav-profile d-flex align-items-center pe-0"
              href="/admin"
              data-bs-toggle="dropdown"
            >
              <span className="d-none d-md-block dropdown-toggle ps-2">
                {user?.userData?.name}
              </span>
            </a>

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li className="dropdown-header">
                <h6>{user?.userData?.name}</h6>
                <span>{user?.userData?.occupation?.toUpperCase()}</span>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="/profile"
                >
                  <i className="bi bi-person"></i>
                  <span>Meu perfil</span>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="/settings"
                >
                  <i className="bi bi-gear"></i>
                  <span>Configurações</span>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="/help"
                >
                  <i className="bi bi-question-circle"></i>
                  <span>Precisa de ajuda?</span>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  href="/"
                  onClick={logout}
                >
                  <i className="bi bi-box-arrow-right"></i>
                  <span>Sair</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
